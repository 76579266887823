.checkbox {
    --background: #fff;
    --border: #969899;
    --border-hover: #616161;
    --border-active: #F38080;
    --tick: #fff;
    position: relative;
    display: flex;
  }
  .checkboxlabel {
    padding-left: 8px;
    cursor: pointer;
    margin-top: -2px;
  }
  .disabled.checkbox {
    cursor: default;
    color: rgba(0, 0, 0, 0.38);
    border: rgba(0, 0, 0, 0.38);
  }
  .disabled .checkboxlabel {
    cursor: default;
  }
  .snowFlakeConnectorCheckboxlabel {
    padding-left: 8px;
    cursor: auto;
    margin-top: -2px;
  }
  .checkbox input,
  .checkbox svg {
    width: 18px;
    height: 18px;
    display: block;
  }
  .checkbox input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: var(--background);
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 2px;
    -webkit-transition: box-shadow .3s;
    transition: box-shadow .3s;
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
  }
  .checkbox input:hover {
    --s: 2px;
    --b: var(--border-hover);
  }
  .checkbox input:checked {
    --b: var(--border-active);
  }
  .checkbox svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--border-active));
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    -webkit-transform: scale(var(--scale, 1)) translateZ(0);
            transform: scale(var(--scale, 1)) translateZ(0);
  }
  .checkbox.path input:checked {
    --s: 2px;
    -webkit-transition-delay: .4s;
            transition-delay: .4s;
  }
  .checkbox.path input:checked + svg {
    --a: 16.1 86.12;
    --o: 102.22;
  }
  .checkbox.path svg {
    stroke-dasharray: var(--a, 86.12);
    stroke-dashoffset: var(--o, 86.12);
    -webkit-transition: stroke-dasharray .6s, stroke-dashoffset .6s;
    transition: stroke-dasharray .6s, stroke-dashoffset .6s;
  }
  .checkbox.bounce {
    --stroke: var(--tick);
  }
  .checkbox.bounce input:checked {
    --s: 11px;
  }
  .checkbox.bounce input:checked + svg {
    -webkit-animation: bounce .4s linear forwards .2s;
            animation: bounce .4s linear forwards .2s;
  }
  .checkbox.bounce svg {
    --scale: 0;
  }
  
  @-webkit-keyframes bounce {
    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }
    75% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes bounce {
    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }
    75% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  