#root {
  height: 100%;
  overflow: 'hidden';
  -webkit-font-smoothing: antialiased;
  /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
}

* {
  -webkit-font-smoothing: antialiased;
  /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
}
.ripple {
  width: 28px;
  height: 28px;
  display: flex;
  position: relative;
  margin-bottom: 10px;
  align-items: center;
}

.ripple::before {
  position: absolute;
  content: "";
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
  border-radius: 50%;
  box-shadow: 0 0 rgba(107, 179, 210, 0.2), 0 0 0 8px rgba(107, 179, 210, 0.2), 0 0 0 16px rgba(107, 179, 210, 0.2), 0 0 0 32px rgba(107, 179, 210, 0.2);
  animation: ripples 1s linear infinite;
  -webkit-animation: ripples 1s linear infinite;
  animation-play-state: running;
  -webkit-animation-play-state: running;
  opacity: 1;
  visibility: visible;
  transition: 0.5s;
  transform: scale(0.8);
}

.owl-nav {
  display: none;
}

@keyframes ripples {
  to {
      box-shadow: 0 0 0 8px rgba(107, 179, 210, 0.2), 0 0 0 16px rgba(107, 179, 210, 0.2), 0 0 0 32px rgba(107, 179, 210, 0.2), 0 0 0 48px rgba(107, 179, 210, 0);
  }
}

.rippleContent:hover {
  transform: scale(1.1);
}

.rippleContent:hover p:first-child {
  color: #e60000;
}

.ripple:hover+.rippleContent p:first-child {
  color: #e60000;
}

.ripple:hover+.rippleContent {
  transform: scale(1.1);
}

*:focus {
  outline: none;
}

div[role='tabpanel'] {
  height: 100%
}

.MuiBox-root {
  height: 100%
}

.description {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes slide-up {
  0% {
      opacity: 0;
      transform: translateY(20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes slide-left300 {
  0% {
      opacity: 0;
      width: 25px;
  }
  100% {
      opacity: 1;
      width: 300px;
  }
}

@keyframes slide-left180 {
  0% {
      opacity: 0;
      width: 25px;
  }
  100% {
      opacity: 1;
      width: 180px;
  }
}

@keyframes slide-left536 {
  0% {
      opacity: 0;
      /* width: 25px; */
  }
  100% {
      opacity: 1;
      /* width: 555px; */
  }
}

@keyframes slide-left220 {
  0% {
      opacity: 0;
      width: 25px;
  }
  100% {
      opacity: 1;
      width: 220px;
  }
}

@keyframes slide-left195 {
  0% {
      opacity: 0;
      width: 25px;
  }
  100% {
      opacity: 1;
      width: 195px;
  }
}

@keyframes slide-left186 {
  0% {
      opacity: 0;
      width: 25px;
  }
  100% {
      opacity: 1;
      width: 186px;
  }
}

@keyframes slide-left425 {
  0% {
      opacity: 0;
      width: 25px;
  }
  100% {
      opacity: 1;
      width: 425px;
  }
}

.datasourceFilterSection {
  /* position: relative; */
  animation: animateright 0.4s;
}

@keyframes animateright {
  from {
      right: -300px;
      opacity: 0
  }
  to {
      right: 0;
      opacity: 1
  }
}

.datasourceFilterSection {
  /* position: relative; */
  animation: animateright1 0.8s;
  animation-timing-function: linear;
}

@keyframes animateright1 {
  from {
      opacity: 0
  }
  to {
      opacity: 1
  }
}

.animateRight100 {
  position: relative;
  animation: animateright 0.4s;
  animation-timing-function: linear;
}

@keyframes animateright {
  from {
      right: -100px;
      opacity: 0
  }
  to {
      right: 0;
      opacity: 1
  }
}

.searchBox:has(.Mui-focused) {
  border: 2px solid red;
}

.density-chart {
  background-color: #eee
}


/* Welcome Page */

@media only screen and (min-width:1200px) {
  .welcomeTiles {
      max-width: 310px !important;
  }
}

rect.hbar {
  -webkit-mask: url(#mask-stripe);
  mask: url(#mask-stripe)
}

.slick-slide {
  margin-right: 15px
}

.MuiDialog-paperFullScreen {
  padding: 0 !important;
}
